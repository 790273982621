import { globalBlue, globalYellow } from '~/modules/AppLayout/Colors';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 60/20日移動平均線 */
export const double_moving_average = createIndicator({
    displayName: '60/20日移動平均',
    id: 'doublema',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = 60;
            const in2 = 20;
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
            const colorIndex = sma1 - sma2 >= 0 ? 1 : 0;
            return [sma1, sma2, colorIndex];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: globalBlue.b900,
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: globalYellow.y800,
                },
            },
            //填充區域
            filledAreasStyle: {
                filledAreaId1: {
                    color: '#fff',
                    transparency: 0,
                    visible: !0,
                },
            },
            palettes: {
                paletteId1: {
                    colors: {
                        0: {
                            color: '#fff',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#fff',
                            width: 0,
                            style: 1,
                        },
                    },
                },
            },
        },
        styles: {
            plot_0: {
                title: '60日',
                histogramBase: 0,
            },
            plot_1: {
                title: '20日',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
