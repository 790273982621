import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
export const tx_settlement_line = createIndicator({
    displayName: '台指結算日',
    id: 'tx_settlement_line',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const input0 = this._input(0);
            const input1 = this._input(1);
            const period = this.PineJS.Std.period(context);
            if (period !== '1D') {
                return [NaN, NaN, NaN, NaN];
            }
            const LARGE_Y_VALUE = 1000000;
            const t = this.PineJS.Std.time(context) / 1000;
            const date = dayAPI.unix(t);
            const dowDiff = date.startOf('month').day() - 3;
            const thirdWednesday = dowDiff <= 0
                ? date.startOf('month').add(14 - dowDiff, 'day')
                : date.startOf('month').add(21 - dowDiff, 'day');
            const isWednesday = date.day() === 3;
            if (input1 && date.isSame(thirdWednesday, 'day'))
                return [NaN, NaN, LARGE_Y_VALUE, -LARGE_Y_VALUE];
            else if (input0 && isWednesday && !date.isSame(thirdWednesday, 'day'))
                return [LARGE_Y_VALUE, -LARGE_Y_VALUE, NaN, NaN];
            return [NaN, NaN, NaN, NaN];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 2,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 60,
                    color: '#00ffdd',
                },
                plot_1: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 2,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 60,
                    color: '#00ffdd',
                },
                plot_2: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 2,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 60,
                    color: '#0099ff',
                },
                plot_3: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 2,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 60,
                    color: '#0099ff',
                },
            },
            precision: 4,
            inputs: {
                in_0: true,
                in_1: true,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'histogram',
            },
            {
                id: 'plot_1',
                type: 'histogram',
            },
            {
                id: 'plot_2',
                type: 'histogram',
            },
            {
                id: 'plot_3',
                type: 'histogram',
            },
        ],
        styles: {
            plot_0: {
                title: '週結算',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '週結算',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: '月結算',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_3: {
                title: '月結算',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: true,
        inputs: [
            {
                id: 'in_0',
                name: '週結算',
                defval: true,
                type: 'bool',
            },
            {
                id: 'in_1',
                name: '月結算',
                defval: true,
                type: 'bool',
            },
        ],
        precision: 2,
        scriptIdPart: '',
    },
});
